@import "../Style/mixin.scss";

.home {
  .brand {
    .white-logo {
      display: none;
    }

    .dark-logo {
      display: block !important;
    }
  }

  .header {
    background-color: transparent;
    box-shadow: unset;

    .right-menu {
      &.flex-design {
        @media (max-width: 990px) {
          display: none;
        }
        &.active {
          display: block;
        }
      }
      ul {
        li {
          a {
            color: var(--dark);

            @media (max-width: 990px) {
              color: var(--dark);
            }
          }
        }
      }
    }

    .cta {
      .btn-outline-dark {
        color: var(--dark);
        border-color: var(--dark);
        background-color: transparent;
      }
    }
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  box-shadow: unset;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 100;
  padding: 1rem 0;

  .flex-menu {
    @include flexDesign($justifyContent: space-between);
  }

  .brand {
    .dark-logo {
      display: none;
    }

    img {
      height: 48px;

      @media (max-width: 500px) {
        height: 52px;
      }
    }
  }

  .nav-menu {
    ul {
      @include flexDesign($justifyContent: space-between);
      list-style: none;
      padding: 0;
      margin: 0;

      @media (max-width: 990px) {
        display: block;
      }

      li {
        position: relative;
        .sub-menu {
          @media (max-width: 990px) {
            display: none;
            width: 100%;
          }
        }

        &:hover {
          .sub-menu {
            display: block;
            @media (max-width: 990px) {
              display: none;
            }
          }
        }
        .sub-menu {
          position: absolute;
          top: 45px;
          left: 0;
          background-color: var(--white);
          border-radius: 15px;
          width: 700px;
          padding: 25px;
          z-index: 9;
          box-shadow: 0 2px 10px #e8e8e8;
          @media (max-width: 990px) {
            position: relative;
            top: 0;
            left: 0;
            box-shadow: unset;
            width: 100%;
            padding: 10px 14px;
          }
          .discover-ideal-services {
            padding: 20px 150px 20px 20px;
            border-radius: 15px;
            position: relative;
            min-height: 153px;
            img {
              height: 122px;
              position: absolute;
              top: 20px;
              right: 20px;
            }
          }
          ul {
            padding: 20px;
            display: block;
            li {
              a {
                display: block;
                color: var(--black);
                padding: 0.5rem 0;

                @media (max-width: 990px) {
                  text-align: left;
                  font-size: 1.3rem;
                }
                @media (max-width: 990px) {
                  font-size: 1.2rem;
                }
                &:hover {
                  @media (max-width: 990px) {
                    color: var(--primary);
                  }
                }
              }
            }
          }
        }
        .sub-menu {
          display: none;
          @media (max-width: 990px) {
            display: none;
          }
          .menu-details {
            display: inline-block;
            .links,
            .discover-ideal-services {
              float: left;
            }
            .links {
              width: 35%;
              @media (max-width: 990px) {
                width: 100%;
              }
              ul,
              li,
              ul li a {
                text-align: left;
              }
              ul {
                padding: 0;
                li {
                  a {
                    padding-left: 0;
                    padding-right: 0;
                    &:hover {
                      color: var(--primary);
                    }
                  }
                }
              }
            }
            .discover-ideal-services {
              width: 65%;
              @media (max-width: 990px) {
                display: none;
              }
            }
          }
        }

        a {
          padding: 0.5rem 1rem;
          color: var(--black);
          display: block;
          text-align: center;
          font-weight: var(--medium);
          line-height: 178%;

          @media (max-width: 990px) {
            text-align: left;
            font-size: 1.3rem;
          }

          &.parent-submenu {
            svg {
              margin-left: 6px;
              @media (max-width: 990px) {
                display: none;
              }
            }
          }
        }
      }

      li {
        ul {
          display: none;
        }
      }
    }
  }
  .mobile-cta {
    display: none;
    @media (max-width: 990px) {
      display: block;
    }
    .discover-ideal-services {
      padding: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 390px) {
        display: block;
      }
      .image {
        @media (max-width: 390px) {
          display: none;
        }
        img {
          width: 130px;
        }
      }
    }
  }
}

.right-menu {
  @media (max-width: 990px) {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    padding: 49px 20px;
  }
  &.flex-design {
    @media (max-width: 990px) {
      display: none;
    }
    &.active {
      display: block;
    }
  }
  &.active {
    display: block;
  }

  .cta {
    padding-left: 20px;

    @media (max-width: 500px) {
      flex-direction: column;
    }
    @media (max-width: 990px) {
      padding: 0 1.4rem;
      text-align: center;

      margin-top: 20px;
      position: absolute;
      bottom: 45px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        width: 100%;
        color: var(--dark) !important;
        &.phone {
          margin-bottom: 15px;
          display: block;
          @media (max-width: 990px) {
            text-align: left;
          }
          @media (max-width: 500px) {
            text-align: center;
          }
        }
      }
    }
    @media (max-width: 577px) {
      text-align: center;
      width: 100%;
      position: relative;
      bottom: 0;
      margin-top: 20px;
    }
  }
}

.hamburger-menu {
  display: none;

  @media (max-width: 990px) {
    display: block;
    z-index: 9;
  }
  &.fixed {
    position: fixed;
    top: 30px;
    right: 30px;
    color: var(--black);
  }
}
