// sticky-social-share
.sticky-social-share {
  @media (max-width: 767.8px) {
    display: none;
  }
  .social-share {
    background-color: var(--bg10);
    border-radius: 30px;
    width: 40px;
    text-align: center;
    padding: 10px 10px;
    ul {
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 6px;
        a {
          font-size: 21px;
          display: block;
        }
      }
    }
  }
}
