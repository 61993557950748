.current-jobs {
    .job-list {
        .job-details {
            padding: 30px;
            background: var(--white);
            min-height: 193px;

            @media(max-width:500px) {
                min-height: auto;
            }

            h2 {
                font-size: 21px;
                line-height: 1.5;
            }

            .qualification {
                font-size: 17px;
                margin-bottom: 10px;

                span {
                    font-weight: 500;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}