@import "../../Style/mixin.scss";

.service-title-section {
  &.spacing {
    padding-top: 6rem;
    padding-bottom: 6rem;
    @media (max-width: 900px) {
      padding-top: 8rem;
      padding-bottom: 3rem;
    }
  }
  .ant-row {
    height: 400px;
    min-height: 80vh;
    @media (max-width: 900px) {
      height: auto;
      min-height: auto;
    }
  }
  h2 {
    font-size: 52px;
    line-height: 67px;
    @media (max-width: 900px) {
      font-size: 35px;
      line-height: 150%;
    }
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  img {
    width: 76%;
    @media (max-width: 400px) {
      width: 96%;
    }
  }
  .circle-transparent {
    right: 0;
    bottom: 11%;
    width: 24%;
    z-index: 0;
  }
  .text-end {
    @media (max-width: 576px) {
      text-align: center !important;
    }
  }
}

// service-help \
.service-help {
  .text-end {
    @media (max-width: 576px) {
      text-align: center !important;
    }
  }
}

.similar-services {
  overflow: hidden;
  .services-list {
    min-height: 345px;
    padding: 30px;
    @media (max-width: 575px) {
      min-height: auto;
      padding: 20px;
    }
    img {
      height: 180px;
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    .count {
      font-size: 38px;
      line-height: 0;
      @include greentextStoke;
    }
  }
  .circle {
    top: 0px;
    right: 0;
    width: 30%;
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0 100%);
  }
}

// practical-fun
.practical-fun {
  .icon {
    img {
      width: 60px;
      height: 60px;
    }
  }
}
