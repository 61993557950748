.cta {
  .border-rounded {
    padding: 50px 50px 0 50px;
    border-radius: 30px;
    @media (max-width: 800px) {
      padding: 25px 25px 0 25px;
    }
  }
  img {
    width: 100%;
    @media (max-width: 768px) {
      width: 50%;
      float: right;
    }
  }
}
