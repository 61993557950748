// roadmap
.roadmap {
  min-height: 447px;
  .rounded {
    padding: 60px;
    border-radius: 30px !important;
    @media (max-width: 757px) {
      padding: 28px;
    }
    img.office {
      width: 30%;
      bottom: 0;
      right: 5%;
      @media (max-width: 800px) {
        position: relative !important;
        width: 60%;
        right: 0;
      }
    }
  }
  .roadmap-line {
    width: 100%;
    left: 0;
    top: 10%;
    @media (max-width: 800px) {
      display: none;
    }
  }
  .title-sec {
    width: 26%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .list1 {
    top: 62%;
    @media (max-width: 800px) {
      top: unset;
    }
  }
  .list2 {
    top: 34%;
    right: 34%;
    @media (max-width: 800px) {
      top: unset;
      right: unset;
    }
  }
  .list3 {
    top: 21%;
    right: 31px;
    @media (max-width: 800px) {
      top: unset;
      right: unset;
    }
  }

  .roadmap-list {
    width: 28%;
    position: absolute;
    @media (max-width: 800px) {
      width: 100%;
      position: relative;
      padding: 30px;
      background: var(--white);
      border-radius: 15px;
      margin-bottom: 10px;
    }
    @media (max-width: 400px) {
      padding: 20px;
    }

    h3 {
      font-size: 22px;
      @media (max-width: 900px) {
        font-size: 18px;
      }
    }
    .count {
      width: 40px;
      font-size: 22px;
      color: var(--white);
      height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      @media (max-width: 900px) {
        margin-bottom: 10px;
      }
    }
  }
}
