.section-box {
  .ant-row {
    position: relative;
    z-index: 1;
  }
  img {
    width: 80%;
    @media (max-width: 766px) {
      margin-bottom: 10px;
      width: 47%;
    }
    @media (max-width: 450px) {
      width: 80%;
    }
  }
}
