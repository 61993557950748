.partners-section {
  &.partners-bg {
    @media (max-width: 500px) {
      background-image: unset;
    }
  }
  .partner-marquee {
    width: 100%;
    overflow: hidden;
  }
}
