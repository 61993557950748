.home-sldier {
  @media (max-width: 700px) {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  .main-image {
    @media (max-width: 500px) {
      display: none;
    }
  }
  .slider-list {
    .ant-row {
      min-height: 550px;
      @media (max-width: 768px) {
        min-height: auto;
      }
    }
    img {
      width: 70%;
      margin: 0 auto;
      @media (max-width: 768px) {
        width: 47%;
      }
    }
    .details {
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    h1 {
      @media (max-width: 990px) {
        font-size: 34px;
      }
      @media (max-width: 700px) {
        font-size: 30px;
      }
    }
    p {
      font-size: 20px;
      line-height: 37px;
      @media (max-width: 700px) {
        font-size: 19px;
      }
    }
    .key-message {
      z-index: 1;
      @media (max-width: 500px) {
        display: none;
      }
      span {
        font-size: 120px;
        line-height: 0;
        opacity: 0.1;
        text-transform: uppercase;
        font-weight: bold;
        @media (max-width: 990px) {
          font-size: 80px;
        }
        @media (max-width: 700px) {
          font-size: 100px;
        }
      }
    }
  }
  .ant-carousel {
    .slick-dots {
      @media (max-width: 600px) {
        margin: 0 auto;
        bottom: -17px;
        justify-content: center;
      }
      @media (max-width: 600px) {
        bottom: -40px;
      }
    }
  }
}
