.footer {
  z-index: 1;
  .footer-links {
    color: var(--black);
    .contact-details {
      a {
        // color: var(--secondary);
        font-weight: bold;
        letter-spacing: 1.5;
      }
    }

    h5 {
      font-weight: 800;
      font-size: 19px;
      letter-spacing: -0.02em;
      margin-bottom: 19px;
    }
    .links {
      .main-title {
        @media (max-width: 480px) {
          display: none;
        }
      }
      .collapsible-title {
        display: none;
        @media (max-width: 480px) {
          display: block;
        }
      }
      .collapsible {
        display: none;

        @media (max-width: 480px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
      ul {
        list-style: none;
        padding: 0;
        margin-top: 20px;
        @media (max-width: 480px) {
          display: none;
        }
        &.show-links {
          display: block !important;
        }
        li {
          a {
            display: inline-block;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 0.94em;
            color: var(--black);
            transition: color 0.1s;
            font-weight: 500;
            &:hover {
              color: var(--primary);
            }
            span {
              display: inline-block;
              margin-right: 8px;
            }
            svg {
              width: 16px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .location-contact {
      width: 100%;
    }
  }
  img {
    height: 33px;
  }
}
.copyright-content {
  border-top: 1px solid rgba(0, 0, 0, 0.075);
  padding-top: 41px;
  margin-top: 39px;
  @media (max-width: 500px) {
    flex-direction: column;
    text-align: center;
    .simple-powerful {
      margin-bottom: 15px;
    }
  }

  p {
    color: #636369;
    @media (max-width: 500px) {
      font-size: 13px;
    }
  }
}

.ant-back-top {
  right: 14px !important;
  bottom: 8px !important;
  width: 30px !important;
  height: 30px !important;
  background: var(--primary);
  text-align: center;
  line-height: 2 !important;
  border-radius: 38px !important;
  color: var(--white) !important;
}
