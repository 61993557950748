.our-blogs {
  .post-details {
    &.bg-white {
      box-shadow: var(--box-shadow2);
    }
  }
}

.recent-post {
  .post-details {
    border-radius: 10px;
    margin: 6px;
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
    &.bg-white {
      // box-shadow: var(--box-shadow2);
    }
    img {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    .details {
      padding: 25px;
    }
  }
}

// slide-navigations
.slide-navigations {
  display: flex;
  .previous,
  .next {
    padding: 6px;
    background: var(--white);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
