@import "./mixin.scss";
// font family
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary: #00cbbf;
  --secondary: #fec247;
  --purple: #bf67c1;
  --green: #cfde88;
  --color: #38393d;
  --white: #fff;
  --gray: #6c757d;

  // hover colors
  --primary-hover: #00b1a6;
  --secondary-hover: #e29b0a;

  //   text properties
  --text: #1c2041;
  --text-second: #5a6183;

  // tag color
  --tag: #0096b7;
  --tag-bg: #d5f7f7;

  // Line
  --line: #ebecf3;

  // bakground
  --site-background: #f8f8fa;

  // error colro
  --error: #ea2b59;

  // success message
  --success: var(--primary);

  // infobox
  --info-box: var(--bg1);

  // font-family
  --inter: "Inter", sans-serif;

  // box shadow
  --box-shadow: 0 3px 16px #d6d8e7;
  --box-shadow1: 3px 1px 9px 0px #cdcdcd;
  --box-shadow2: 0px 0px 14px 0px rgba(69, 69, 69, 0.1);

  // border
  --border: 1px solid rgba(0, 0, 0, 0.12);

  // font-weight
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;

  //   backgrounds
  --bg1: #fff2db;
  --bg2: #bffffb;
  --bg3: #fed0ff;
  --bg4: #eeebff;
  --bg5: #ffded6;
  --bg6: #defffd;
  --bg7: #ffe2b8;
  --bg8: #f7f7ff;
  --bg9: #040e18;
  --bg10: #f6efff;
  // --bg11: #00948a;
  --bg11: #f7f7f8;
}

body {
  counter-reset: section;
  overflow-x: hidden;
  overflow-x: hidden;
}

html,
body {
  font-size: 1rem;
  color: var(--text);
  font-family: var(--inter);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 150%;
  }
}
a {
  text-decoration: none;

  &:hover {
    color: var(--primary);
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--inter);
  font-weight: var(--bold);
}

img {
  @include imageSelectnone;
}

// heading tags with font size
h1 {
  font-size: 48px;
  line-height: 54px;
  @media (max-width: 800px) {
    font-size: 30px;
    line-height: 150%;
  }
}

h2 {
  font-size: 32px;
  line-height: 45px;
  @media (max-width: 800px) {
    font-size: 25px;
    line-height: 150%;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
}

h3 {
  font-size: 24px;
  line-height: 39px;
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 150%;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
}

h4 {
  font-size: 18px;
  line-height: 28px;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 150%;
  }
}
h5 {
  font-size: 16px;
  line-height: 28px;
  @media (max-width: 800px) {
    font-size: 15px;
    line-height: 150%;
  }
}
h6 {
  font-size: 15px;
  line-height: 28px;
  @media (max-width: 800px) {
    font-size: 15px;
    line-height: 150%;
  }
}
b,
strong {
  font-weight: 500;
}

a {
  outline: none !important;
  text-decoration: none !important;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  /*--Drag--*/
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

// container
.container {
  margin: 0 auto;
  max-width: 1183px;
  width: 100%;
  padding: 0 20px;
}

// flex design
.flex-design {
  @include flexDesign($justifyContent: center);
}

// Title section =============================

.title {
  margin-bottom: 5rem;
  font-size: 3.5rem;
}

.highlight-title {
  h1 {
    font-size: 2rem;

    @media (max-width: 980px) {
      font-size: 1.6rem;
    }

    @media (max-width: 550px) {
      font-size: 1.4rem;
    }
  }

  h2 {
    font-size: 3rem;

    @media (max-width: 980px) {
      font-size: 2.4rem;
    }

    @media (max-width: 550px) {
      font-size: 1.6rem;
    }
  }
}

// colors =================================
.text-white {
  color: var(--white);
}

.text-black {
  color: var(--text) !important;
}
.text-gray {
  color: var(--text-second);
}
.text-error {
  color: var(--error);
}
.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}
.text-purple {
  color: var(--purple);
}
.text-green {
  color: var(--green);
}
.text-green-stroke {
  @include greentextStoke;
}

// text decoration
.text-decoration-underline {
  text-decoration: underline !important;
}

// text ellipsis =================================
.text-ellipsis3 {
  @include textEllipse;
}
.text-ellipsis2 {
  @include textEllipse($line: 2);
}
// background colors ==============================
.bg-yellow {
  background-color: var(--secondary);
}
.bg-blue {
  background-color: var(--primary);
}
.bg-purple {
  background-color: var(--purple);
}
.bg-green {
  background-color: var(--green);
}
.bg1 {
  background-color: var(--bg1);
}
.bg2 {
  background-color: var(--bg2);
}
.bg3 {
  background-color: var(--bg3);
}
.bg4 {
  background-color: var(--bg4);
}
.bg5 {
  background-color: var(--bg5);
}
.bg6 {
  background-color: var(--bg6);
}
.bg7 {
  background-color: var(--bg7);
}
.bg8 {
  background-color: var(--bg8);
}
.bg9 {
  background-color: var(--bg9);
}
.bg10 {
  background-color: var(--bg10);
}
.bg11 {
  background-color: var(--bg11);
}
.bg12 {
  background-color: #ecf5ff;
}
.bg-white {
  background-color: var(--white);
}
.bg-primary {
  background-color: var(--primary) !important;
}
.bg-light {
  background-color: #fcfcec;
}
.bg-gray {
  background-color: #333333;
}
.bg-black {
  background-color: var(--black);
}
// text-ellipse ===========
.text-ellipse1 {
  @include textEllipse($line: 1);
}
.text-ellipse2 {
  @include textEllipse($line: 2);
}
.text-ellipse3 {
  @include textEllipse($line: 3);
}

// grandient colors ========================

.bg-blue-gradient {
  background: linear-gradient(135deg, #d8fffb 30.9%, #f9f8f6 79.86%);
}
.bg-yellow-gradient {
  background: linear-gradient(135deg, #ffeacb 30.9%, #ffeecd 79.86%);
}
.bg-purple-gradient {
  background: linear-gradient(135deg, #fedfff 30.9%, #fdadff 79.86%);
}
.bg-green-gradient {
  background: linear-gradient(135deg, #f9ffdc 10.07%, #f4ffbe 79.86%);
}
.bg-lightyellow-gradient {
  background: linear-gradient(135deg, #f6f5f3 30.9%, #f9f8f6 79.86%);
}
.bg-yellow-purple-gradient {
  @include bgyellowpurpleGradient;
}
.bg-yellow-lineargradient {
  background: linear-gradient(
    180deg,
    rgba(255, 231, 196, 0.59) 0%,
    rgba(255, 231, 196, 0) 100%
  );
}
// mesh background =========================
.bg-mesh {
  background: url("../assets/images/shapes/mesh-gradient.svg");
  @include imageNorepeat;
}
// shapes
.section-top1 {
  &::before {
    @include beforeContent($background: var(--bg4), $height: 100%, $top: 0);
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
    @media (max-width: 600px) {
      clip-path: polygon(0 1%, 100% 0, 100% 99%, 0 100%);
    }
  }
}

.section-top2 {
  &::before {
    @include beforeContent($background: var(--bg2), $height: 100%, $top: 0);
    clip-path: polygon(0 39%, 100% 66%, 100% 100%, 0% 100%);
  }
}
.section-top3 {
  &::before {
    @include beforeContent($background: #ffd598, $height: 100%, $top: 0);
    clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 100%);
  }
}
.partners-bg {
  background-image: url("../assets/images/our-team.png");
  @include imageNorepeat($size: 80%);
}
.shape-home-gradient {
  position: relative;
  &:before {
    @include beforeContent(
      $top: 0,
      $right: 0,
      $left: unset,
      $width: 100%,
      $background: url("../assets/images/shapes/gradient-home.svg")
    );
    @include imageNorepeat($size: 72%, $position: top right);
    @media (max-width: 700px) {
      background-size: 175%;
      background-position: top center;
    }
  }
}
.shape-blue-gradient {
  position: relative;
  &:before {
    @include beforeContent(
      $top: -22%,
      $right: 0,
      $width: 45%,
      $left: unset,
      $background: url("../assets/images/shapes/gradient-blue.svg")
    );
    @include imageNorepeat($size: 70%, $position: right);
  }
}
.shape-purple-gradient {
  position: relative;
  &:before {
    @include beforeContent(
      $top: -22%,
      $right: 0,
      $left: unset,
      $width: 45%,
      $background: url("../assets/images/shapes/gradient-pink.svg")
    );
    @include imageNorepeat($size: 70%, $position: right);
  }
}
.shape-green-gradient {
  position: relative;
  &:before {
    @include beforeContent(
      $top: -22%,
      $right: 0,
      $left: unset,
      $width: 45%,
      $background: url("../assets/images/shapes/gradient-green.svg")
    );
    @include imageNorepeat($size: 70%, $position: right);
  }
}
.shape-yellow-gradient {
  position: relative;
  &:after {
    @include beforeContent(
      $top: unset,
      $bottom: -29%,
      $left: 0,
      $background: url("../assets/images/shapes/gradient-yellow.svg")
    );
    @include imageNorepeat($size: 70%, $position: left);
  }
}
.shape-about-gradient {
  position: relative;
  &:before {
    @include beforeContent(
      $top: 0,
      $left: 0,
      $background: url("../assets/images/shapes/gradient-about.svg")
    );
    @include imageNorepeat($size: 58%, $position: left);
  }
}
// box shadow ==============
.box-shadow2 {
  box-shadow: var(--box-shadow2);
}
// font-size================
.font-1 {
  @include font1;
}

.font-2 {
  @include font2;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.font-3 {
  @include font3;
}

.font-4 {
  @include font4;
}

.font-5 {
  @include font5;
}

.font-6 {
  @include font6;
}
// cursor-pointer ==============================
.cursor-pointer {
  cursor: pointer;
}
// font weight =================================
.font-weight-normal {
  font-weight: var(--regular);
}
.font-weight-medium {
  font-weight: var(--medium);
}
.font-weight-semibold {
  font-weight: var(--semibold);
}
.font-weight-bold {
  font-weight: bold;
}
// viewport height =================================

.viewport-height {
  min-height: 100vh;
}
.min-viewport-height {
  min-height: 60vh;
}
// z-index ========================================

.z-index {
  position: relative;
  z-index: 9;
}
.z-index0 {
  z-index: 0;
}
// min-height =====================================
.min-height {
  padding: 11rem 0 4rem 0;
  min-height: 550px;
  @media (max-width: 990px) {
    padding: 7rem 0 4rem 0;
    min-height: auto;
  }
}
.border-radius {
  border-radius: 16px;
}
// card style =====================================
.card {
  @include Card($border: unset);
}

// button styles ===================================

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-outline-dark {
  color: var(--text);
  border-color: var(--dark);
}

.btn-outline-warning {
  color: var(--warning);
  border-color: var(--warning);
}

.btn-warning {
  color: var(--black);
  background-color: var(--warning);
  border-color: var(--warning);
}

// spacing ========================================
.spacing {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (max-width: 800px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media (max-width: 500px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  &.main-page {
    @media (max-width: 990px) {
      padding-top: 7rem;
      padding-bottom: 4rem;
    }
  }
}
.spacing-top {
  padding-top: 4rem;

  @media (max-width: 500px) {
    padding-top: 3rem;
  }
}
.spacing-bottom {
  padding-bottom: 4rem;

  @media (max-width: 500px) {
    padding-bottom: 3rem;
  }
}

.top-spacing {
  padding-top: 4rem;
  @media (max-width: 500px) {
    padding-top: 3rem;
  }
}

.left-spacing {
  padding-left: 3rem;
}

// callto-action ============
.callto-action {
  .ant-btn {
    background-color: var(--text) !important;
    border-color: var(--text) !important;
  }
  .rotate {
    top: -41%;
    @include objRotate;
    @media (max-width: 800px) {
      top: -27%;
      width: 20%;
    }
  }
  .flower-image {
    bottom: 24%;
    right: 8%;
    height: 54px;
  }
}

.newsletter-email-capture {
  overflow: hidden;
  @media (max-width: 600px) {
    text-align: center;
  }
  .subscription {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .image {
      width: 50%;
      height: 100%;
      background-color: #a6f8f3;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
    .details {
      width: 50%;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
  img {
    right: -43px;
    bottom: 7px;
    height: 86px;
  }
  form {
    input {
      min-height: 39px;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      padding: 4px 10px;
      width: 100%;
      margin-bottom: 15px;
      &:focus,
      &:hover,
      &:focus-visible {
        outline: none;
      }
      @media (max-width: 576px) {
        margin-bottom: 15px;
      }
    }
    .ant-btn-submit {
      background-color: var(--text);
      border-color: var(--text);
      color: var(--white);
    }
  }
  img.postman {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    @media (max-width: 500px) {
      min-width: 40%;
      max-width: 40%;
    }
  }
  .details {
    padding: 30px;
  }
}

// forms

form {
  .field {
    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}

// blog-card
.blog-card {
  .thumbnail {
    img {
      @include borderRadiusTLR;
    }
  }
  .details {
    padding: 20px;
    box-shadow: var(--box-shadow2);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

// contact-support
.contact-support {
  .zigzag-shape {
    @media (max-width: 750px) {
      display: none;
    }
  }
  .circle-blue-shape {
    @media (max-width: 750px) {
      display: none;
    }
  }
}
