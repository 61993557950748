.social-share {
  ul {
    li {
      a {
        color: var(--dark-blue);
      }
    }
  }
}

.content-area {
  h1 {
    font-size: 28px;
    line-height: 41px;
  }
}

// content-area
.recent-blogs {
  padding: 10px;
  @media (max-width: 767.8px) {
    display: none;
  }
  .image {
    img {
      width: 100%;
    }
  }
}
