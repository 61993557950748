@import "../../Style/mixin.scss";

.services-section {
  .puzzle-image {
    margin-top: 26px;
    @media (max-width: 600px) {
      display: none;
    }
  }
  .service-list {
    margin-bottom: 20px;
    &.card {
      padding: 20px 20px 20px 140px;
      position: relative;
      min-height: 132px;
      @media (max-width: 350px) {
        padding: 20px;
      }
      .image {
        width: 108px;
        @include objPosition($position: absolute, $top: 20px, $left: 20px);
        @media (max-width: 350px) {
          position: relative;
          top: 0;
          left: 0;
          margin-bottom: 10px;
        }
      }
      .count {
        width: 30px;
        height: 30px;
        color: var(--white);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        @include objPosition($position: absolute, $top: 40%, $right: -10px);
        @media (max-width: 577px) {
          display: none;
        }
        @media (max-width: 350px) {
          display: block;
          top: 8%;
          left: unset;
          right: 15px;
        }
      }
    }
    &.reverse {
      &.card {
        padding: 20px 140px 20px 20px;
        @media (max-width: 577px) {
          padding: 20px 20px 20px 140px;
        }
        @media (max-width: 350px) {
          padding: 20px;
        }
        .image {
          @include objPosition($position: absolute, $top: 20px, $right: 20px);
          @media (max-width: 577px) {
            right: unset;
            left: 20px;
          }
          @media (max-width: 350px) {
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: 10px;
          }
        }
        .count {
          @include objPosition(
            $position: absolute,
            $top: 40%,
            $left: -10px,
            $right: unset
          );
          @media (max-width: 350px) {
            display: block;
            top: 8%;
            left: unset;
            right: 15px;
          }
        }
      }
    }
  }
}
