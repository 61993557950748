@import "../../Style/mixin.scss";

.about-us {
  .image {
    width: 60%;
    float: right;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .about-circle {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    z-index: 1;
  }
}

.meet-simplifier {
  .prath-profile {
    padding: 50px;
    background-image: url("../../assets/images/shapes/wave.svg");
    @include imageNorepeat($size: 37%, $position: bottom right);
    @media (max-width: 800px) {
      padding: 30px;
    }
    @media (max-width: 500px) {
      padding: 20px;
    }
  }
}
.our-partners {
  a {
    padding: 0 10px;
    img {
      height: 40px;
    }
  }
}
.why-simple-ideas {
  .content {
    width: 60%;
    position: relative;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .image {
    width: 69%;
  }
  .circle {
    top: 37%;
    right: 10%;
    z-index: 0;
    width: 50%;
  }
}
