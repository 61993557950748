@import "../../Style/mixin.scss";

.circle-half {
  width: 24%;
  right: 0;
  top: 0;
}

.selection-card {
  position: relative;
  .selected {
    display: none;
  }
  &.active {
    .selected {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .selection-services-list {
    min-height: 315px;
    padding: 21px;
    border-radius: 10px;
    img {
      height: 180px;
      width: 100%;
      display: block;
      margin-bottom: 29px;
    }
    .count {
      position: absolute;
      bottom: 33px;
      right: 16px;
      font-size: 38px;
      line-height: 0;
      @include greentextStoke;
    }
  }
}

.wayof-working-selection {
  .working-options {
    text-align: center;
    &.min-height {
      min-height: 155px;
    }
    img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    @include cardForm;
    &:hover {
      border-color: var(--primary);
    }
  }
}

// choose-level-intrest
.choose-level-intrest {
  @include cardForm;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  &:hover {
    border-color: var(--primary);
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
