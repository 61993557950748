@import "../../Style/mixin.scss";

.contact-title-section {
  .semicircle {
    width: 88%;
    right: -40px;
  }
  .contact-ways {
    .details {
      padding: 20px;
      border-radius: 15px;
      &.bg-primary {
        color: var(--white);
        a {
          color: var(--white);
        }
      }
      h3 {
        font-size: 20px;
      }
      .icon {
        display: flex;
        background-color: var(--white);
        border: 1px solid var(--line);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        justify-content: center;
        svg {
          width: 22px;
          color: var(--text);
        }
      }
      .position-absolute {
        right: 20px;
        top: 20px;
        width: 18px;
      }
    }
  }
}

.contact-card {
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--white);

  ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;

    li {
      a {
        display: inline-block;
        margin-bottom: 10px;
        color: #343434;

        span {
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}

.zigzag-shape {
  width: 120px;
  top: 17%;
  @media (max-width: 500px) {
    display: none;
  }
}
.circle-blue-shape {
  width: 120px;
  top: 60%;
  right: 10%;
  @media (max-width: 500px) {
    display: none;
  }
}
.contact-form {
  form {
    input,
    select,
    textarea {
      padding: 10px 20px;
      border-radius: 0.2rem;
      border: 1px solid #c2c2c2;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

// know-more-btn
.know-more-btn {
  bottom: -19px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-table;
  img {
    width: 114px;
    height: 114px;
  }
}

// become-partner-team
.become-partner-team {
  .details {
    @include Card($border: unset, $padding: 30px);
    img {
      height: 137px;
    }
  }
}
