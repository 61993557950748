@mixin flexDesign(
  $alignItem: center,
  $justifyContent: center,
  $flexDirection: row
) {
  display: flex;
  align-items: $alignItem;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
}
@mixin imageSelectnone {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
@mixin greentextStoke {
  color: transparent;
  -webkit-text-fill-color: transparent;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--secondary);
}
// card design
@mixin Card($border: 1px solid var(--line), $padding: 20px, $radius: 12px) {
  width: 100%;
  border-radius: $radius;
  border: $border;
  padding: $padding;
}

// text ellipse
@mixin textEllipse($line: 3) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
// common fonts and styles for application
@mixin font1 {
  font-size: 18px;
  font-weight: var(--semibold);
  line-height: 28px;
}

@mixin font2 {
  font-size: 16px;
  font-weight: var(--regular);
  line-height: 24px;
}

@mixin font3 {
  font-size: 16px;
  font-weight: var(--medium);
  line-height: 24px;
}

@mixin font4 {
  font-size: 14px;
  font-weight: var(--regular);
  line-height: 25px;
}

@mixin font5 {
  font-size: 14px;
  font-weight: var(--semibold);
  line-height: 20px;
}

@mixin font6 {
  font-size: 12px;
  font-weight: var(--regular);
  line-height: 18px;
}

@mixin textEllipse($line: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin objPosition(
  $position: absolute,
  $top: unset,
  $left: unset,
  $right: unset,
  $bottom: unset
) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

@mixin imageNorepeat(
  $repeat: no-repeat,
  $size: cover,
  $position: center center
) {
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}

@mixin beforeContent(
  $top: 0,
  $left: 0,
  $right: unset,
  $bottom: unset,
  $width: 100%,
  $height: 100%,
  $background: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.8)),
  $opacity: 1
) {
  content: "";
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
  width: $width;
  height: $height;
  background: $background;
  opacity: $opacity;
}

// cardform
@mixin cardForm {
  padding: 20px;
  border: 1.5px solid #858585;
  border-radius: 7px;
}

@mixin borderRadiusTLR($left: 10px, $right: 10px) {
  border-top-left-radius: $left;
  border-top-right-radius: $right;
}

// gradient colors
@mixin bgyellowpurpleGradient {
  background: linear-gradient(137deg, #e391e8 0%, #ffd378 95.74%);
}

// animation image rotation
@mixin objRotate($time: 3s) {
  animation: rotation $time infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
