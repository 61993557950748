// why-simplified-way
.why-simplified-way {
  .operating-principle {
    .card {
      padding: 20px 20px 20px 96px;
      .icon {
        position: absolute;
        left: 20px;
        top: 20px;
        width: 60px;
        height: 60px;
        padding: 14px;
      }
    }
  }
  .shape {
    right: 0;
    top: 37%;
    width: 15%;
  }
  h4 {
    @media (max-width: 800px) {
      font-size: 20px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

// our-approach
.our-approach {
  @media (max-width: 576px) {
    text-align: center;
  }
  img {
    width: 75%;
    @media (max-width: 576px) {
      width: 50%;
    }
  }
}
// .guiding-principle
.guiding-principle {
  img {
    &.guidelines {
      height: 185px;
    }
  }
}

// partners-logo
.partners-logo {
  @media (max-width: 500px) {
    flex-direction: column;
  }
  a {
    padding: 0 10px;
    &.list-items {
      width: 168px;
    }
  }
  img {
    height: 64px;
    @media (max-width: 600px) {
      height: 64px;
    }
  }
}

// bringing-simplicity
.bringing-simplicity {
  .shape {
    width: 30px;
    height: 30px;
    bottom: -30px;
    right: 20%;
  }
  h3 {
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }
  h2 {
    @media (max-width: 800px) {
      font-size: 25px;
    }
  }
}
