.faq {
  .ant-collapse-item {
    border-bottom: 1px solid var(--line) !important;
    .ant-collapse-header {
      position: relative;
      padding-right: 20px !important;
      font-size: 1rem;
      font-weight: 500;
    }
    .ant-collapse-expand-icon {
      position: absolute;
      top: 23px;
      right: -12px;
    }
    .ant-collapse-content-box {
      p {
        font-size: 1rem;
      }
    }
  }
}
