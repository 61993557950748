.ant-layout {
  background: var(--white);
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}
a {
  color: var(--primary);
  text-decoration: none;

  &:hover {
    color: var(--primary);
    text-decoration: none;
  }
}

// Antd Button styles

a {
  &.ant-btn {
    padding-top: 4px !important;
  }
}
.ant-btn {
  .arrow-right {
    rotate: -40deg;
  }
}
.ant-btn,
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  min-height: 40px;
  border-radius: 3px;
  box-shadow: unset;
  &.ant-btn-sm {
    min-height: 30px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.ant-btn-primary,
.ant-btn-submit,
.ant-btn-default,
.ant-btn-secondary {
  border-radius: 50px;
}
.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-submit,
.ant-btn-submit:active {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-submit:hover,
.ant-btn-submit:focus {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}
.ant-btn-secondary,
.ant-btn-secondary:active {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: var(--white);
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
}
.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:focus {
  color: var(--primary);
  border: 1.5px solid var(--primary);
  background-color: transparent;
  &.white {
    border: 0;
  }
}

// tags
.ant-tag {
  padding: 6px 16px;
  font-size: 15px;
  border-radius: 20px;
  border: 0;
}

// collapsible items
.ant-collapse-ghost {
  .ant-collapse-item {
    border-bottom: 1px solid var(--dark-line);
  }
}
.ant-collapse {
  .ant-collapse-item {
    background-color: transparent;
    .ant-collapse-header,
    .ant-collapse-content .ant-collapse-content-box {
      padding: 12px 0;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-top: 0;
      }
    }
  }
}

// message popup
.tawk-chat-bubble.tawk-message-preview-chat-bubble {
  font-size: 13px;
  line-height: 30px;
}

// modal box
.ant-modal-content {
  border-radius: 10px;
}
.ant-modal-header {
  border-radius: 10px 10px 0 0;
}

// ant-input
.ant-input {
  &:hover,
  &:focus,
  &:active {
    border-color: #d9d9d9;
    box-shadow: none;
  }
}

// carousel =============
.ant-carousel {
  .slick-dots {
    justify-content: right;
    margin-right: 0;
    margin-bottom: 0;
    li {
      button {
        background: var(--text);
      }
      &.slick-active {
        button {
          background: var(--primary);
        }
      }
    }
  }
}

// steps
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: var(--primary) !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primary) !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary) !important;
}
